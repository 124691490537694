import { useState, useRef, useEffect } from "react";
import {
    Box,
    Flex,
    Spacer,
    Link,
    Tooltip,
    Avatar,
    IconButton,
    useToast,
    Image,
    Text,
    Spinner,
    Button,
    Divider,
    Center,
    HStack,
    Heading,
    useColorModeValue,
} from "@chakra-ui/react";
import { ref, remove, set, update } from "firebase/database";
import { db } from "../firebase";
import { FaThumbsUp, FaThumbsDown, FaVolumeUp, FaCopy, FaPause, FaStar, FaTrophy, FaEquals } from 'react-icons/fa';
import { AlternativeMessagesInterface, MessageInterface } from "../configs";
import fetchStreamAudio from "../helpers/fetchStreamAudio";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { keyframes } from "@chakra-ui/react";

const pulseBorder = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(23, 64, 77, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(23, 64, 77, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(23, 64, 77, 0);
  }
`;

interface AssistantBoxProps {
    box: MessageInterface;
    idx: number;
    currentChat: string;
    allReferences: any[];
    showThumbs: boolean;
    apiAddress: string;
    status: string;
    alternativeMessages: AlternativeMessagesInterface;
}

const AssistantBox: React.FC<AssistantBoxProps> = ({ box, idx, currentChat, allReferences, showThumbs, apiAddress, status, alternativeMessages }) => {
    const [showAlternativeAnswer, setShowAlternativeAnswer] = useState<boolean>(false);
    const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
    const [alternativeChosen, setAlternativeChosen] = useState<boolean>(false);
    const [currentAlternative, setCurrentAlternative] = useState<any>(null);
    const audioSourceRef = useRef<AudioBufferSourceNode|null>(null);
    const toast = useToast();

    // Update currentAlternative whenever alternativeMessages changes
    useEffect(() => {
        if (alternativeMessages?.answers) {
            const alternatives = Object.values(alternativeMessages.answers);
            const currentAlt = alternatives.find(x => x?.index === idx);
            setCurrentAlternative(currentAlt || null);
        } else {
            setCurrentAlternative(null);
        }
    }, [alternativeMessages, idx]);

    function thumbsUp(box: MessageInterface, idx: number) {
        if (box.review && box.review.thumbs === 'up') {
            const thumbsDownRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx + '/review');
            remove(thumbsDownRef);
        } else {
            const thumbsDownRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx);
            update(thumbsDownRef, { review: { thumbs: 'up' } });
        }
    }

    function thumbsDown(box: MessageInterface, idx: number) {
        if (box.review && box.review.thumbs === 'down') {
            const thumbsDownRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx + '/review');
            remove(thumbsDownRef);
        } else {
            const thumbsDownRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx);
            update(thumbsDownRef, { review: { thumbs: 'down' } });
        }
    }

    function getLabelFromUrl(url: string|undefined) {
        if (url) {
            const anchor = url.split('?anchor=')[1];
            const ref = allReferences.find((ref: any) => ref.url_anchor === anchor);
            if (ref) {
                return `${ref.title} ${ref.subtitle ? '(' + ref.subtitle + (ref.heading ? ': ' + ref.heading : '') + ') ' : ''}`;
            }
        }
        return;
    }

    function prepareText(text: string) {
        text = text.replace(/\\n/g, "\n");
        if (text.charAt(0) === '"' && text.charAt(text.length - 1) === '"') {
            text = text.slice(1, -1);
        }
        
        const refRegex = /[\u3010\uFF3B\[]((?:\d+(?:,\s*\d+)*))[\u3011\uFF3D\]]/g;
        
        text = text.replace(refRegex, (match: any, numbers: string) => {
            const indices = numbers.split(',').map(num => parseInt(num.trim()) - 1);
            
            const processedRefs = indices.map(idx => {
                if (idx >= 0 && idx < allReferences.length) {
                    let url = '';
                    let section = allReferences[idx].title ? allReferences[idx].title : allReferences[idx];
                    
                    if (/\d/.test(section.split(' ')[0])) {
                        section = section.split(' ')[0];
                        url = allReferences[idx].title 
                            ? `https://app.sullivanoncomp.com/soc/index/title/${section}?anchor=${allReferences[idx].url_anchor}` 
                            : `https://app.sullivanoncomp.com/soc/index/title/${section}`;
                        return ` [[SOC Section ${section}]](${url})`;
                    } else {
                        if (allReferences[idx].url_anchor && allReferences[idx].url_anchor.toLowerCase().includes('glossary')) {
                            url = `https://app.sullivanoncomp.com/glossary`;
                        } else {
                            url = `https://app.sullivanoncomp.com/calculators/${allReferences[idx].url_anchor}`;
                            return ` [[SOC ${section} Calculator]](${url})`;
                        }
                        return ` [[SOC ${section}]](${url})`;
                    }
                }
                return '';
            }).filter(ref => ref !== '');
            
            return processedRefs.join(' ');
        });
        
        return text;
    }

    function copyMessage(message: string) {
        message = prepareText(message);
        navigator.clipboard.writeText(message);
        toast({
            position: 'top',
            title: 'Message copied to clipboard',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    }

    function handleShowAlternativeAnswer() {
        if (!currentAlternative.seen) {
            const key = Object.keys(alternativeMessages.answers).find(
                (x) => alternativeMessages.answers[x].index === idx
            );
            if (key) {
                update(ref(db, 'alternativeMessages/' + currentChat + '/answers/' + key), {
                    seen: true
                });
            }
        }
        setShowAlternativeAnswer(!showAlternativeAnswer);
    }

    function handlePickFavoriteAnswer(votingResult: string) {
        const key = Object.keys(alternativeMessages.answers).find(
            (x) => alternativeMessages.answers[x].index === idx
        );
        if (key) {
            update(ref(db, 'alternativeMessages/' + currentChat + '/answers/' + key), {
                result: votingResult,
            });
            if (votingResult === "better") {
                update(ref(db, 'rawChatMessages/' + currentChat), {
                    [idx]: currentAlternative.message
                });
                update(ref(db, 'chats/' + currentChat), {
                    references: alternativeMessages.references ? alternativeMessages.references : []
                });
            }
        }
        setShowAlternativeAnswer(false);
    }

    function displayText(box: any, isAlternative: boolean = false) {
        const text = prepareText(box.content[0].text);
        return (
            <Box
                fontSize="16px"
                lineHeight="180%"
                fontFamily="Bitter, serif"
                w="65%"
                fontWeight={400}
                padding="1.5%"
                boxShadow="md"
                paddingLeft="3%"
                borderRadius="10px"
                bg="#F7F7F8"
                position="relative"
                pb="40px"
            >
                <ReactMarkdown
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                    components={{
                        a: ({node, href, ...props}) => (
                            <Tooltip label={getLabelFromUrl(href)}>
                                <Link
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(href, '_blank');
                                    }}
                                    color="#006f98"
                                    {...props}
                                />
                            </Tooltip>
                        ),
                        h1: ({node, ...props}) => <Box as="h1" fontSize="24px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                        h2: ({node, ...props}) => <Box as="h2" fontSize="20px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                        h3: ({node, ...props}) => <Box as="h3" fontSize="18px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                        h4: ({node, ...props}) => <Box as="h4" fontSize="16px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                        h5: ({node, ...props}) => <Box as="h5" fontSize="14px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                        p: ({node, ...props}) => <Box as="p" marginBottom="1em" {...props} />,
                        ul: ({node, ...props}) => <Box as="ul" paddingLeft="20px" marginBottom="1em" listStyleType="disc" {...props} />,
                        ol: ({node, ...props}) => <Box as="ol" paddingLeft="20px" marginBottom="1em" listStyleType="decimal" {...props} />,
                        li: ({node, ...props}) => <Box as="li" marginBottom="0.5em" {...props} />,
                        hr: ({node, ...props}) => <Box as="hr" marginBottom="1em" {...props} />,
                        blockquote: ({node, ...props}) => (
                            <Box
                                as="blockquote"
                                paddingLeft="1em"
                                borderLeft="4px solid #ccc"
                                marginBottom="1em"
                                fontStyle="italic"
                                {...props}
                            />
                        ),
                        code: ({node, ...props}) => (
                            <Box
                                as="code"
                                padding="0.2em 0.4em"
                                backgroundColor="#f5f5f5"
                                borderRadius="4px"
                                fontFamily="monospace"
                                {...props}
                            />
                        ),
                    }}
                >
                    {text}
                </ReactMarkdown>
                {status === 'completed' && (
                    <Flex right="5" bottom="5" position="absolute" zIndex={1}>
                        <Spacer />
                        {box.provider && (
                            <Tooltip label={box.provider === 'openai' ? "Answered using OpenAI" : "Answered using Claude"} placement="top" borderRadius="10px" closeOnScroll={true}>
                                <Image marginRight="1%" h="18px" borderRadius="20px" src={box.provider === 'openai' ? "openai_logo.png" : "claude_logo.png"} />
                            </Tooltip>
                        )}
                        {currentAlternative && !currentAlternative.seen && (
                            <Button
                                borderRadius="20px"
                                size="sm"
                                variant="outline"
                                borderColor="#17404D"
                                color="#17404D"
                                animation={`${pulseBorder} 2s infinite`}
                                onClick={() => {handleShowAlternativeAnswer();}}
                            >
                                Alternative answer available. Click to view.
                            </Button>
                        )}
                        <IconButton
                            size="sm"
                            onClick={() => {
                                copyMessage(text);
                            }}
                            aria-label="Copy answer"
                            colorScheme="gray"
                            variant="link"
                            icon={<FaCopy />}
                        />
                        <IconButton
                            onClick={() => {
                                streamAudio(box.content[0].text);
                            }}
                            size="md"
                            aria-label="Listen answer"
                            colorScheme="gray"
                            variant="link"
                            icon={!audioPlaying ? <FaVolumeUp /> : <FaPause />}
                        />
                    </Flex>
                )}
            </Box>
        );
    }

    async function streamAudio(text: string) {
        if (audioSourceRef.current) {
            audioSourceRef.current.stop();
            audioSourceRef.current = null;
            setAudioPlaying(false);
            return;
        }

        setAudioPlaying(true);
        try {
            await fetchStreamAudio(text, audioSourceRef, setAudioPlaying, apiAddress);
        } catch (error) {
            console.error('Error fetching the audio:', error);
            setAudioPlaying(false);
        }
    }

    function getStatusMessage(status: string) {
        if (status === 'running') {
            return "Evaluating question...";
        } else if (status === 'retrieving') {
            return "Looking for documents in Sullivan on Comp...";
        } else if (status === 'writing') {
            return "Working on the answer...";
        }
        return '';
    }

    function PreferenceSelector() {
      
        // A subtle gradient based on #163c49:
        const boxBg = "linear-gradient(135deg, #163c49 0%, #297492 100%)";
        
        // Lighten or darken text color for better contrast:
        const headingColor = "white";
        const infoTextColor = "whiteAlpha.800";
        
        // Use light borders on top of the dark background:
        const borderColor = "whiteAlpha.300";
      
        return (
          <Box
            maxW="lg"
            mx="auto"
            mt={8}
            p={8}
            borderRadius="xl"
            borderWidth="1px"
            borderColor={borderColor}
            bg={boxBg}
            boxShadow="md"
            textAlign="center"
            position="relative"
            overflow="hidden"
          >
            <Heading
              mb={4}
              color={headingColor}
              fontSize="2xl"
              letterSpacing="wide"
            >
              Which answer do you prefer?
            </Heading>
      
            <Text mb={6} color={infoTextColor} fontSize="lg">
              Please select your preference before continuing the conversation.
            </Text>
      
            <HStack spacing={5} justify="center">
              <Tooltip label="Click if you prefer the first answer" placement="top">
                <Button
                  leftIcon={<FaTrophy />}
                  onClick={() => handlePickFavoriteAnswer("worse")}
                  variant="outline"
                  size="lg"
                  borderRadius="30px"
                  borderColor="whiteAlpha.600"
                  color="whiteAlpha.900"
                  _hover={{
                    bg: "whiteAlpha.200",
                    transform: 'translateY(-2px)',
                    boxShadow: 'md',
                  }}
                  transition="all 0.2s"
                >
                  Prefer #1
                </Button>
              </Tooltip>
      
              <Tooltip label="Click if you feel they are equally good" placement="top">
                <Button
                  leftIcon={<FaEquals />}
                  variant="outline"
                  size="lg"
                  onClick={() => handlePickFavoriteAnswer("equal")}
                  borderRadius="30px"
                  borderColor="whiteAlpha.600"
                  color="whiteAlpha.900"
                  _hover={{
                    bg: "whiteAlpha.200",
                    transform: 'translateY(-2px)',
                    boxShadow: 'md',
                  }}
                  transition="all 0.2s"
                >
                  Equal
                </Button>
              </Tooltip>
      
              <Tooltip label="Click if you prefer the second answer" placement="top">
                <Button
                  leftIcon={<FaTrophy />}
                  onClick={() => handlePickFavoriteAnswer("better")}
                  variant="outline"
                  size="lg"
                  borderRadius="30px"
                  borderColor="whiteAlpha.600"
                  color="whiteAlpha.900"
                  _hover={{
                    bg: "whiteAlpha.200",
                    transform: 'translateY(-2px)',
                    boxShadow: 'md',
                  }}
                  transition="all 0.2s"
                >
                  Prefer #2
                </Button>
              </Tooltip>
            </HStack>
          </Box>
        );
      }

    return (
        <Box>
            <Flex padding="2%">
                <Tooltip label={getStatusMessage(status)} placement="top-start" borderRadius="10px" isOpen={status !== 'completed'} colorScheme="yellow">
                    <Avatar marginRight="2%" h="50px" w="50px" src="chat_avatar.png">
                        {status !== 'completed' && (
                            <Spinner h="51px" w="51px" left="-2.5%" position="absolute" color="#17404d" borderWidth="4px"/>
                        )}
                    </Avatar>
                </Tooltip>
                {['writing', 'completed'].includes(status) && (
                    <Flex w="100%">
                        {displayText(box)}
                        {currentAlternative?.seen && currentAlternative?.result === "pending" && (
                            <>
                                <Divider orientation="vertical" margin="3%" />
                                {displayText(currentAlternative.message, true)}
                            </>
                        )}
                        {showThumbs && status === 'completed' && !showAlternativeAnswer && (
                            <Flex>
                                <IconButton 
                                    id={`ChatSOC - Response Liked ${idx}`} 
                                    onClick={() => {thumbsUp(box, idx);}} 
                                    marginTop="auto" 
                                    marginBottom="auto" 
                                    marginLeft="10%" 
                                    aria-label="Like" 
                                    colorScheme="gray" 
                                    variant={box.review && box.review.thumbs === 'up' ? "solid" : "outline"} 
                                    borderRadius="20px" 
                                    icon={<FaThumbsUp style={{ pointerEvents: 'none' }} color={box.review && box.review.thumbs === 'up' ? "green" : "black"} />} 
                                />
                                <IconButton 
                                    id={`ChatSOC - Response Disliked ${idx}`} 
                                    aria-label="Dislike" 
                                    onClick={() => {thumbsDown(box, idx)}} 
                                    marginTop="auto" 
                                    marginBottom="auto" 
                                    marginLeft="10%" 
                                    colorScheme="gray" 
                                    variant={box.review && box.review.thumbs === 'down' ? "solid" : "outline"} 
                                    borderRadius="20px" 
                                    zIndex={1} 
                                    icon={<FaThumbsDown style={{ pointerEvents: 'none' }} color={box.review && box.review.thumbs === 'down' ? "red" : "black"} />} 
                                />
                            </Flex>
                        )}
                    </Flex>
                )}
            </Flex>
            {currentAlternative?.seen && currentAlternative?.result === "pending" && (
                PreferenceSelector()
            )}
        </Box>
    );
};

export default AssistantBox;