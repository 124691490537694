import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import AssistantBox from "../components/AssistantBox";
import { AlternativeMessagesInterface, MessageInterface } from "../configs";
import { FaRegFile } from "react-icons/fa";
import { ref, onValue, set } from 'firebase/database';
import { db } from '../firebase';

interface ChatMessagesProps {
  targetRef: React.RefObject<HTMLDivElement>;
  chatMessages: MessageInterface[];
  currentChat: string;
  allReferences: string[];
  apiAddress: string;
  status: string;
  alternativeMessages: AlternativeMessagesInterface;
}



const ChatMessages: React.FC<ChatMessagesProps> = React.memo(({ status, targetRef, chatMessages, currentChat, allReferences, apiAddress, alternativeMessages }) => {
  
  return (
    <Box id="chat-container" ref={targetRef} marginTop="4%">
      {chatMessages.map((x, idx) => (
        <Box key={`message-${idx}`}>
          {x.role === 'assistant' && (!chatMessages[idx + 1] || chatMessages[idx + 1].content[0].type !== 'tool_result')  && (
            <AssistantBox
              box={x}
              idx={idx}
              currentChat={currentChat}
              allReferences={allReferences}
              showThumbs={true}
              apiAddress={apiAddress}
              status={idx > chatMessages.length - 2 ? status : 'completed'}
              alternativeMessages={idx === chatMessages.length - 1 ? alternativeMessages : {answers: {}, references: []}}
            />
          )}
          {x.role === 'user' && x.content[0].type === 'text' && (
            <Flex key={`message-${idx}`}>
              <Box
                fontFamily="Bitter, serif"
                padding="1%"
                fontSize="16px"
                lineHeight="28px"
                boxShadow="md"
                maxW="60%"
                marginTop="25px"
                marginBottom="25px"
                fontWeight={400}
                color="gray.700"
                marginLeft="auto"
                borderRadius="10px"
                bg="#F7F7F8"
                marginRight="1%"
                textAlign="left">
                <Text>{x.content[0].text}</Text>
                {x.uploadedFiles && x.uploadedFiles.map((y, fileIdx) => (
                  <Button
                    key={`${y.id}-file-${fileIdx}`}
                    size="sm"
                    cursor="auto"
                    variant="outline"
                    leftIcon={<FaRegFile />}
                    marginTop="10px"
                  >
                    {y.title.split('/').pop()}
                  </Button>
                ))}
              </Box>
            </Flex>
          )}
        </Box>
      ))}
    </Box>
  );
});

export default ChatMessages;