import React, { useState, useCallback, memo, useEffect, useRef } from 'react';
import { InputGroup, Textarea, InputRightElement, IconButton, Flex, useToast } from "@chakra-ui/react";
import { FaPaperPlane, FaStop } from "react-icons/fa";
import ResizeTextarea from "react-textarea-autosize";
import fetchStopMessage from '../helpers/fetchStopMessage';
import { ChatInterface, MessageInterface } from '../configs';
import fetchAutocompleteQuestion from '../helpers/fetchAutocompleteQuestion';
import debounce from 'lodash/debounce';
import { set } from 'firebase/database';

interface DialogueProps {
    dialogueBox: string;
    handleDialogueBoxChange: (value: string) => void;
    sendMessage: () => void;
    waitAnswer: boolean;
    allChats: {[key:string]: ChatInterface};
    currentChat: string;
    isDesktop: boolean|undefined;
    apiAddress: string;
    chatMessages: MessageInterface[];
}

const DialogueInput = memo(function DialogueInput({
    dialogueBox,
    handleDialogueBoxChange,
    sendMessage,
    waitAnswer,
    allChats,
    currentChat,
    isDesktop,
    apiAddress,
    chatMessages,
}: DialogueProps) {
    const toast = useToast();
    const [localDialogueBox, setLocalDialogueBox] = useState(dialogueBox);
    const [suggestion, setSuggestion] = useState('');
    const [textareaHeight, setTextareaHeight] = useState('auto');
    const lastInputRef = useRef('');

    useEffect(() => {
        setLocalDialogueBox(dialogueBox);
    }, [dialogueBox]);

    useEffect(() => {
        setSuggestion('');
    }, [currentChat]);

    // Keep fetchSuggestion with useCallback since it uses debounce
    const fetchSuggestion = useCallback(
        debounce(async (value: string) => {
            if (value.trim()) {
                try {
                    const completion = await fetchAutocompleteQuestion(currentChat, value, apiAddress);
                    if (completion.includes(value) && value === lastInputRef.current) {
                        setSuggestion(completion);
                    }
                } catch (error) {
                    console.error('Autocomplete error:', error);
                }
            } else {
                setSuggestion('');
            }
        }, 500),
        [currentChat, apiAddress]
    );

    function handleLocalChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const value = e.target.value;
        lastInputRef.current = value;
        setLocalDialogueBox(value);
        handleDialogueBoxChange(value);
        if (!suggestion.startsWith(value) || !value) {
            setSuggestion('');
        }
        if (value.trim()) {
            fetchSuggestion(value);
        }
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Tab' && suggestion) {
            e.preventDefault();
            setLocalDialogueBox(suggestion);
            handleDialogueBoxChange(suggestion);
            setSuggestion('');
        } else if (e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey) {
            if (currentChat) {
                if (['completed', 'canceled'].includes(allChats[currentChat].status)) {
                    e.preventDefault();
                    sendMessage();
                }
            } else {
                e.preventDefault();
                sendMessage();
            }
            
        }
    }

    function handleStopMessage() {
        fetchStopMessage(currentChat, apiAddress);
        toast({
            position: 'top',
            title: "Cancelling message...",
            status: "info",
            duration: 6000
        });
    }

    function handleResize(height: number) {
        setTextareaHeight(`${height}px`);
    }

    return (
        <InputGroup w="67%" size='md'>
            <Textarea 
                isDisabled={waitAnswer}
                placeholder="Type your question for ChatSOC here..." 
                padding="2%" 
                size="large" 
                paddingRight="5rem" 
                borderRadius="15px" 
                rows={1} 
                boxShadow="md" 
                border="1px solid #b4b4b4" 
                value={localDialogueBox} 
                onChange={handleLocalChange} 
                onKeyDown={handleKeyDown}
                color="blackAlpha.800" 
                as={ResizeTextarea}
                maxRows={4} 
                id="dialogue-box"
                minHeight={textareaHeight}
                onHeightChange={handleResize}
            />
            {suggestion && localDialogueBox && (
                <Textarea 
                    position="absolute"
                    padding="2%" 
                    size="large" 
                    paddingRight="5rem" 
                    borderRadius="15px" 
                    rows={1} 
                    border="1px solid #b4b4b4" 
                    placeholder={suggestion} 
                    color="blackAlpha.800" 
                    as={ResizeTextarea}
                    maxRows={4}
                    minHeight={textareaHeight}
                    onHeightChange={handleResize}
                />
            )}
            <InputRightElement width={isDesktop ? '7rem' : '5rem'} height="100%">
                {allChats[currentChat]?.status && allChats[currentChat].status !== 'completed'
                    ? <IconButton
                        marginLeft="20%"
                        isDisabled={!Object.keys(allChats[currentChat]).includes('status')}
                        aria-label="Stop"
                        size={isDesktop ? "md" : "sm"}
                        colorScheme={localDialogueBox.length === 0 ? 'gray' : "blackAlpha"}
                        borderRadius="25px"
                        icon={<FaStop color={localDialogueBox.length === 0 ? "#b4b3b3" : "#163c49"} />}
                        onClick={handleStopMessage}
                    />
                    : <Flex align="center">
                        <IconButton
                            marginLeft="40%"
                            aria-label="Send Message"
                            size={isDesktop ? "md" : "sm"}
                            isLoading={waitAnswer}
                            borderRadius="25px"
                            bg={localDialogueBox.length === 0 ? 'gray' : "#163c49"}
                            icon={<FaPaperPlane color={localDialogueBox.length === 0 ? "#b4b3b3" : "white"} />}
                            onClick={sendMessage}
                            id={`ChatSOC - Chat Submission ${Math.floor(chatMessages.length/2) + 1}`}
                        />
                    </Flex>}
            </InputRightElement>
        </InputGroup>
    );
});

export default DialogueInput;